import styled from 'styled-components';
import { color, spacer } from 'theme';
import { variable } from '../../../../theme/functions';
import Curve from '../../../Curve/Curve';
import Icon from '../../../Icon/Icon';
import Typography from '../../../Typography/Typography';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';

export const StyledTypography = styled(Typography)`
  color: ${color('black')};
`;

export const StyledRegularIcon = styled(Icon)`
  fill: ${color('primary-300')};
  color: ${color('primary-300')};
`;

export const StyledCurve = styled(Curve)`
  position: absolute;
  bottom: 100%;
  right: 0%;

  &::before {
    transition: ${variable('transition')};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${spacer(75)};
  padding: ${spacer(150)};
  width: 100%;
  height: fit-content;
  z-index: 1;
  background-color: ${color('white')};
  border-radius: 0 0 ${variable('border-radius')} ${variable('border-radius')};
  transition: ${variable('transition')};
`;

export const TileContentHighlightWrapper = styled(ContentfulCallToAction)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: relative;
  height: 400px;

  ${variable('border')}

  &:hover {
    ${ContentWrapper} {
      background-color: ${color('primary-300')};
    }

    ${StyledTypography}, ${StyledRegularIcon} {
      color: ${color('white')};
      fill: ${color('white')};
    }

    ${StyledCurve} {
      &::before {
        box-shadow: 50px 50px 0 0 ${color('primary-300')};
      }
    }
  }
`;

export const StyledImageBlock = styled(ContentfulImageBlock)`
  width: 100%;
  height: 100%;
`;
