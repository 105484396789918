import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import ContentfulTileContentTextual from '../ContentfulTileContentTextual/ContentfulTileContentTextual';

export const StyledDesktopTilesWrapper = styled.div`
  display: none;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      gap: ${spacer(50)};
      flex: 1;
      overflow: auto;

      > * {
        min-width: 80%;
      }
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${spacer(50)};
    `
  )}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${spacer(100)};

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
    `
  )}
`;

export const StyledMobileWrapper = styled.div`
  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `
  )}
`;

export const StyledMobileTileContentTextual = styled(ContentfulTileContentTextual)`
  height: 100%;
`;
