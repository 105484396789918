import { Spacer } from 'constants/styling';
import styled, { css } from 'styled-components';
import { color, spacer, variable } from 'theme';
import Icon from '../../../Icon/Icon';
import Typography from '../../../Typography/Typography';
import ContentfulIcon from '../ContentfulIcon/ContentfulIcon';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

interface StyledPageLinkProps {
  padding?: Spacer;
}

const iconStyling = css`
  fill: ${color('primary-300')};
  color: ${color('primary-300')};
`;

export const StyledTypography = styled(Typography)`
  color: ${color('black')};
`;

export const StyledContentfulIcon = styled(ContentfulIcon)`
  ${iconStyling}
`;

export const StyledRegularIcon = styled(Icon)`
  ${iconStyling}
`;

export const StyledPageLink = styled(ContentfulPageLink)<StyledPageLinkProps>`
  ${variable('border')}
  gap: ${spacer(150)};
  padding: ${({ padding }) => spacer(padding || 150)};
  padding-left: ${spacer(150)}; // Always overwrite padding-left with 1.5rem
  border-radius: ${variable('border-radius')};
  width: 100%;
  position: relative;
  display: flex;
  transition: ${variable('transition')};

  &:hover {
    background-color: ${color('primary-300')};

    ${StyledTypography}, ${StyledContentfulIcon}, ${StyledRegularIcon} {
      color: ${color('white')};
      fill: ${color('white')};
    }
  }
`;
