import { Spacer } from 'constants/styling';
import { ITileContent } from 'types/ContentfulTypes';
import { ICON_NAMES } from '../../../../constants/icon';
import Flex from '../../../Flex/Flex';
import {
  StyledContentfulIcon,
  StyledPageLink,
  StyledRegularIcon,
  StyledTypography,
} from './ContentfulTileContentTextual.styled';

interface ContentfulTileContentTextualProps {
  className?: string;
  content: ITileContent;
  padding?: Spacer;
}

const ContentfulTileContentTextual = ({ className, content, padding }: ContentfulTileContentTextualProps) => {
  if (!content?.fields) {
    return null;
  }

  const { description, icon, link: cta, title } = content.fields;
  const link = cta?.fields?.link;

  return (
    <StyledPageLink link={link} padding={padding} className={className}>
      {icon && <StyledContentfulIcon color="" content={icon} size={150} preventLink />}

      <Flex flexDirection="column" gap={50} fullWidth>
        <StyledTypography color="" fontWeight="bold">
          {title}
        </StyledTypography>

        <Flex alignItems="flex-end" justifyContent="space-between" fullWidth>
          <StyledTypography color="">{description}</StyledTypography>

          <StyledRegularIcon type="fa" color="" name={ICON_NAMES.ANGLE_RIGHT} size={125} />
        </Flex>
      </Flex>
    </StyledPageLink>
  );
};

export default ContentfulTileContentTextual;
