import Flex from 'components/Flex/Flex';
import { produce } from 'immer';
import { ITileContent } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import { ICON_NAMES } from '../../../../constants/icon';
import { CALL_TO_ACTION_TYPES } from '../types';
import {
  ContentWrapper,
  StyledCurve,
  StyledImageBlock,
  StyledRegularIcon,
  StyledTypography,
  TileContentHighlightWrapper,
} from './ContentfulTileContentHighlight.styled';

interface ContentfulTileContentHighlightProps {
  content: ITileContent;
  sizes?: ImageSizes;
}

const ContentfulTileContentHighlight = ({
  content,
  sizes = {
    [BREAKPOINTS.XL]: 580,
    [BREAKPOINTS.LG]: 680,
    [BREAKPOINTS.MD]: 475,
    [BREAKPOINTS.SM]: 743,
    [DEFAULT_SIZE_KEY]: 400,
  },
}: ContentfulTileContentHighlightProps) => {
  if (!content?.fields) {
    return null;
  }

  const { description, image, link: cta, title } = content.fields;

  // Exclude the image's link as this would cause a link inside of a link error
  const slimmedImage = produce(image, (draft) => {
    if (draft) {
      draft.fields.link = undefined;
    }
  });

  return (
    <TileContentHighlightWrapper content={cta} type={CALL_TO_ACTION_TYPES.LINK}>
      {slimmedImage && <StyledImageBlock content={slimmedImage} sizes={sizes} />}
      <ContentWrapper>
        <StyledCurve height={200} width={100} />
        <StyledTypography color="" marginBottom={0} tag="h3" type="h2">
          {title}
        </StyledTypography>
        <Flex alignItems="flex-end" justifyContent="space-between">
          <StyledTypography color="">{description}</StyledTypography>
          <StyledRegularIcon type="fa" color="" name={ICON_NAMES.ANGLE_RIGHT} size={125} />
        </Flex>
      </ContentWrapper>
    </TileContentHighlightWrapper>
  );
};

export default ContentfulTileContentHighlight;
