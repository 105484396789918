import Box from 'components/Box/Box';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import breakpointValues from 'theme/variables/breakpoints';
import { IGroupingUsp } from 'types/ContentfulTypes';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulTileContentHighlight from '../ContentfulTileContentHighlight/ContentfulTileContentHighlight';
import ContentfulTileContentTextual from '../ContentfulTileContentTextual/ContentfulTileContentTextual';
import {
  ContentWrapper,
  StyledDesktopTilesWrapper,
  StyledMobileTileContentTextual,
  StyledMobileWrapper,
} from './ContentfulGroupingHighlight.styled';

interface ContentfulGroupingHighlightProps {
  content: IGroupingUsp;
}

const ContentfulGroupingHighlight = ({ content }: ContentfulGroupingHighlightProps) => {
  if (!content?.fields) {
    return null;
  }

  const { highlightedItem, items, title } = content.fields;

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 2 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 4 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 8 },
    },
  };

  const showDots = { [BREAKPOINTS.XS]: false };
  const showArrows = { [BREAKPOINTS.MD]: true };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.2,
    [BREAKPOINTS.MD]: 2,
    [BREAKPOINTS.LG]: 4,
    [BREAKPOINTS.XL]: 8,
  };

  return (
    <ContentfulGroupingWrapper>
      <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>

      <ContentWrapper>
        {!!items?.length && (
          <>
            <StyledMobileWrapper>
              <SliderEmbla slidesToShow={slidesToShow} showArrows={showArrows} showDots={showDots} options={options}>
                {items.map((item) => (
                  <Box key={item.sys.id} paddingRight={50} fullHeight>
                    <StyledMobileTileContentTextual
                      key={item.sys.id}
                      content={item}
                      padding={items?.length > 6 ? 75 : 150}
                    />
                  </Box>
                ))}
              </SliderEmbla>
            </StyledMobileWrapper>

            <StyledDesktopTilesWrapper>
              {items.map((item) => (
                <ContentfulTileContentTextual key={item.sys.id} content={item} padding={items?.length > 6 ? 75 : 150} />
              ))}
            </StyledDesktopTilesWrapper>
          </>
        )}

        <ContentfulTileContentHighlight content={highlightedItem} />
      </ContentWrapper>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingHighlight;
